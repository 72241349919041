export const upgradeTriggers = [
  'appbar-trial-progress',
  'appbar',
  'diagram-draft-create',
  'diagram-group-create',
  'domain-create',
  'flow-create',
  'flow-editor-create-path',
  'flow-editor-create',
  'flow-editor-name-picker-create',
  'landscape-duplicate',
  'landscape-import',
  'landscape-new',
  'model-object-create',
  'organization-billing-plan',
  'organization-billing-seats',
  'organization-members-editor',
  'organization-ownership-teams',
  'organization-user-invite-dialog-editor',
  'organization-user-invite-dialog',
  'reality-links-per-item',
  'reality-links',
  'sign-up',
  'tag-picker-tag-group-new',
  'tag-toolbar-tag-group-new',
  'team-new',
  'trial-progress',
  'version-menu-create-info',
  'version-menu-freeze-object',
  'version-menu-list-info',
  'version-timeline',
  'website'
] as const

export type UpgradeTrigger = typeof upgradeTriggers[number]
