import * as fabBitbucket from '@fortawesome/free-brands-svg-icons/faBitbucket'
import * as fabGitlab from '@fortawesome/free-brands-svg-icons/faGitlab'
import * as fabMicrosoft from '@fortawesome/free-brands-svg-icons/faMicrosoft'
import * as farFileAlt from '@fortawesome/free-regular-svg-icons/faFileAlt'
import * as fasArrowLeft from '@fortawesome/free-solid-svg-icons/faArrowLeft'
import * as fasBook from '@fortawesome/free-solid-svg-icons/faBook'
import * as fasCalculator from '@fortawesome/free-solid-svg-icons/faCalculator'
import * as fasCaretDown from '@fortawesome/free-solid-svg-icons/faCaretDown'
import * as fasChalkboardTeacher from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher'
import * as fasCheck from '@fortawesome/free-solid-svg-icons/faCheck'
import * as fasCheckCircle from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import * as fasChevronDown from '@fortawesome/free-solid-svg-icons/faChevronDown'
import * as fasChevronLeft from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import * as fasChevronRight from '@fortawesome/free-solid-svg-icons/faChevronRight'
import * as fasClock from '@fortawesome/free-solid-svg-icons/faClock'
import * as fasCloud from '@fortawesome/free-solid-svg-icons/faCloud'
import * as fasCog from '@fortawesome/free-solid-svg-icons/faCog'
import * as fasCommentDots from '@fortawesome/free-solid-svg-icons/faCommentDots'
import * as fasCopy from '@fortawesome/free-solid-svg-icons/faCopy'
import * as fasCreditCard from '@fortawesome/free-solid-svg-icons/faCreditCard'
import * as fasEllipsisH from '@fortawesome/free-solid-svg-icons/faEllipsisH'
import * as fasExclamationTriangle from '@fortawesome/free-solid-svg-icons/faExclamationTriangle'
import * as fasEye from '@fortawesome/free-solid-svg-icons/faEye'
import * as fasFile from '@fortawesome/free-solid-svg-icons/faFile'
import * as fasGift from '@fortawesome/free-solid-svg-icons/faGift'
import * as fasHandHoldingHeart from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart'
import * as fasHistory from '@fortawesome/free-solid-svg-icons/faHistory'
import * as fasHourglassEnd from '@fortawesome/free-solid-svg-icons/faHourglassEnd'
import * as fasInfoCircle from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import * as fasLayerGroup from '@fortawesome/free-solid-svg-icons/faLayerGroup'
import * as fasLink from '@fortawesome/free-solid-svg-icons/faLink'
import * as fasLock from '@fortawesome/free-solid-svg-icons/faLock'
import * as fasPaperPlane from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import * as fasPen from '@fortawesome/free-solid-svg-icons/faPen'
import * as fasPencilAlt from '@fortawesome/free-solid-svg-icons/faPencilAlt'
import * as fasPeopleArrows from '@fortawesome/free-solid-svg-icons/faPeopleArrows'
import * as fasPercent from '@fortawesome/free-solid-svg-icons/faPercent'
import * as fasPlus from '@fortawesome/free-solid-svg-icons/faPlus'
import * as fasQuestion from '@fortawesome/free-solid-svg-icons/faQuestion'
import * as fasRandom from '@fortawesome/free-solid-svg-icons/faRandom'
import * as fasSearchPlus from '@fortawesome/free-solid-svg-icons/faSearchPlus'
import * as fasServer from '@fortawesome/free-solid-svg-icons/faServer'
import * as fasShare from '@fortawesome/free-solid-svg-icons/faShare'
import * as fasSitemap from '@fortawesome/free-solid-svg-icons/faSitemap'
import * as fasSnowflake from '@fortawesome/free-solid-svg-icons/faSnowflake'
import * as fasSortDown from '@fortawesome/free-solid-svg-icons/faSortDown'
import * as fasSortUp from '@fortawesome/free-solid-svg-icons/faSortUp'
import * as fasTag from '@fortawesome/free-solid-svg-icons/faTag'
import * as fasTags from '@fortawesome/free-solid-svg-icons/faTags'
import * as fasThumbsDown from '@fortawesome/free-solid-svg-icons/faThumbsDown'
import * as fasTimes from '@fortawesome/free-solid-svg-icons/faTimes'
import * as fasTrash from '@fortawesome/free-solid-svg-icons/faTrash'
import * as fasTrashAlt from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import * as fasUserAlt from '@fortawesome/free-solid-svg-icons/faUserAlt'
import * as fasUserCheck from '@fortawesome/free-solid-svg-icons/faUserCheck'
import * as fasUserCog from '@fortawesome/free-solid-svg-icons/faUserCog'
import * as fasUserEdit from '@fortawesome/free-solid-svg-icons/faUserEdit'
import * as fasUserLock from '@fortawesome/free-solid-svg-icons/faUserLock'
import * as fasUserPlus from '@fortawesome/free-solid-svg-icons/faUserPlus'
import * as fasUsers from '@fortawesome/free-solid-svg-icons/faUsers'

export default [
  fabBitbucket.definition,
  fabGitlab.definition,
  fabMicrosoft.definition,
  farFileAlt.definition,
  fasArrowLeft.definition,
  fasBook.definition,
  fasCalculator.definition,
  fasCaretDown.definition,
  fasChalkboardTeacher.definition,
  fasCheck.definition,
  fasCheckCircle.definition,
  fasChevronDown.definition,
  fasChevronLeft.definition,
  fasChevronRight.definition,
  fasClock.definition,
  fasCloud.definition,
  fasCog.definition,
  fasCommentDots.definition,
  fasCopy.definition,
  fasCreditCard.definition,
  fasEllipsisH.definition,
  fasExclamationTriangle.definition,
  fasEye.definition,
  fasFile.definition,
  fasGift.definition,
  fasHandHoldingHeart.definition,
  fasHistory.definition,
  fasHourglassEnd.definition,
  fasInfoCircle.definition,
  fasLayerGroup.definition,
  fasLink.definition,
  fasLock.definition,
  fasPaperPlane.definition,
  fasPen.definition,
  fasPencilAlt.definition,
  fasPeopleArrows.definition,
  fasPercent.definition,
  fasPlus.definition,
  fasQuestion.definition,
  fasRandom.definition,
  fasSearchPlus.definition,
  fasServer.definition,
  fasShare.definition,
  fasSitemap.definition,
  fasSnowflake.definition,
  fasSortDown.definition,
  fasSortUp.definition,
  fasTag.definition,
  fasTags.definition,
  fasThumbsDown.definition,
  fasTimes.definition,
  fasTrash.definition,
  fasTrashAlt.definition,
  fasUserAlt.definition,
  fasUserCheck.definition,
  fasUserCog.definition,
  fasUserEdit.definition,
  fasUserLock.definition,
  fasUserPlus.definition,
  fasUsers.definition
] as const
