import * as analytics from '@icepanel/app-analytics'
import { OrganizationBillingCurrency, OrganizationBillingCycle, OrganizationPlan, OrganizationStatus, PermissionType } from '@icepanel/platform-api-client'

import { UpgradeTrigger, upgradeTriggers } from './upgrade-triggers'

export const organizationBillingCurrency: analytics.Property<OrganizationBillingCurrency | undefined> = {
  displayName: 'Organization billing currency',
  examples: ['cad', 'eur', 'gbp', 'usd'],
  name: 'organization_billing_currency',
  required: false,
  type: 'string'
}
export const organizationBillingCycle: analytics.Property<OrganizationBillingCycle | undefined> = {
  displayName: 'Organization billing cycle',
  examples: ['annually', 'monthly'],
  name: 'organization_billing_cycle',
  required: false,
  type: 'string'
}
export const organizationBillingEmail: analytics.Property<string | undefined> = {
  displayName: 'Organization billing email',
  name: 'organization_billing_email',
  required: false,
  type: 'string'
}
export const organizationName: analytics.Property<string> = {
  displayName: 'Organization name',
  name: 'organization_name',
  required: true,
  type: 'string'
}
export const organizationPlan: analytics.Property<OrganizationPlan> = {
  displayName: 'Organization plan',
  name: 'organization_plan',
  required: true,
  type: 'string'
}
export const organizationSeats: analytics.Property<number> = {
  displayName: 'Organization seats',
  name: 'organization_seats',
  required: true,
  type: 'number'
}
export const organizationStatus: analytics.Property<OrganizationStatus> = {
  displayName: 'Organization status',
  examples: ['active', 'canceled', 'incomplete', 'incomplete_expired', 'past_due', 'trialing', 'unpaid'],
  name: 'organization_status',
  required: true,
  type: 'string'
}
export const organizationApiKeyName: analytics.Property<string> = {
  displayName: 'Organization api key name',
  name: 'organization_api_key_name',
  required: true,
  type: 'string'
}
export const organizationApiKeyPermission: analytics.Property<PermissionType> = {
  displayName: 'Organization api key permission',
  name: 'organization_api_key_permission',
  required: true,
  type: 'string'
}
export const organizationAzureDevopsAccountName: analytics.Property<string> = {
  displayName: 'Organization Azure DevOps account name',
  name: 'organization_azure_devops_account_name',
  required: true,
  type: 'string'
}
export const organizationBitbucketWorkspaceName: analytics.Property<string> = {
  displayName: 'Organization Bitbucket workspace name',
  name: 'organization_bitbucket_workspace_name',
  required: true,
  type: 'string'
}
export const organizationBitbucketServerUrl: analytics.Property<string> = {
  displayName: 'Organization Bitbucket server URL',
  name: 'organization_bitbucket_server_url',
  required: true,
  type: 'string'
}
export const organizationBitbucketServerUsername: analytics.Property<string> = {
  displayName: 'Organization Bitbucket server username',
  name: 'organization_bitbucket_server_username',
  required: true,
  type: 'string'
}
export const organizationGitlabGroupName: analytics.Property<string> = {
  displayName: 'Organization GitLab group name',
  name: 'organization_azure_gitlab_group_name',
  required: true,
  type: 'string'
}
export const organizationUpgradeTrigger: analytics.Property<UpgradeTrigger> = {
  displayName: 'Organization upgrade trigger',
  examples: [...upgradeTriggers],
  name: 'organization_upgrade_trigger',
  required: true,
  type: 'string'
}
export const organizationTrial: analytics.Property<boolean> = {
  displayName: 'Organization trial',
  name: 'organization_trial',
  required: true,
  type: 'boolean'
}
export const organizationUpgradePlan: analytics.Property<OrganizationPlan> = {
  displayName: 'Organization upgrade plan',
  name: 'organization_upgrade_plan',
  required: true,
  type: 'string'
}
export const organizationUpgradeTrial: analytics.Property<boolean> = {
  displayName: 'Organization upgrade trial',
  name: 'organization_upgrade_trial',
  required: true,
  type: 'boolean'
}
export const organizationShareLinksEnabled: analytics.Property<boolean> = {
  displayName: 'Organization share links enabled',
  name: 'organization_share_links_enabled',
  required: true,
  type: 'boolean'
}
export const organizationShareLinkAuthDomains: analytics.Property<string[] | null> = {
  displayName: 'Organization share link auth domains',
  name: 'organization_share_link_auth_domains',
  required: true,
  type: 'array'
}

/**
 * Hidden
 */

export const organizationGoalNameHidden: analytics.Property<string> = {
  displayName: 'Organization goal name',
  examples: [
    'add-model-object-description',
    'contact-icepanel',
    'create-flow',
    'create-object-in-model-view',
    'create-version',
    'find-golden-brian',
    'invite-organization-member',
    'share-link-visitor',
    'submit-feedback',
    'tag-model-object'
  ],
  hidden: true,
  name: 'organization_goal_name',
  required: true,
  type: 'string'
}
export const organizationGoalProgressCountHidden: analytics.Property<number> = {
  displayName: 'Organization goal progress count',
  hidden: true,
  name: 'organization_goal_progress_count',
  required: true,
  type: 'number'
}
export const organizationGoalProgressDataHidden: analytics.Property<string[]> = {
  displayName: 'Organization goal progress data',
  hidden: true,
  name: 'organization_goal_progress_data',
  required: true,
  type: 'array'
}
export const organizationGoalStepCountHidden: analytics.Property<number> = {
  displayName: 'Organization goal step count',
  hidden: true,
  name: 'organization_goal_step_count',
  required: true,
  type: 'number'
}
export const organizationDeleteReasonHidden: analytics.Property<string> = {
  displayName: 'Organization delete reason',
  hidden: true,
  name: 'organization_delete_reason',
  required: true,
  type: 'string'
}
