import { Organization } from '@icepanel/platform-api-client'

export interface IOrganizationLimits {
  actionLogDays: number
  diagramDrafts: number
  diagramGroups: number
  domains: number
  flowPaths: boolean
  flows: number
  flowsPerDiagram: number
  landscapes: number
  links: number
  linksPerItem: number
  modelObjects: number
  seats: number
  shareLinkProtected: boolean
  shareLinkVersions: boolean
  tagGroups: number
  teams: boolean
  versionObjects: boolean
  versionRevert: boolean
  versions: number
}

export default (organization?: Organization | null): IOrganizationLimits => {
  if (organization?.plan === 'isolation' || organization?.plan === 'pilot' || organization?.plan === 'growth' || organization?.plan === 'indie' || organization?.status === 'trialing') {
    return {
      actionLogDays: Infinity,
      diagramDrafts: Infinity,
      diagramGroups: Infinity,
      domains: Infinity,
      flowPaths: true,
      flows: Infinity,
      flowsPerDiagram: Infinity,
      landscapes: Infinity,
      links: Infinity,
      linksPerItem: Infinity,
      modelObjects: Infinity,
      seats: organization.seats,
      shareLinkProtected: true,
      shareLinkVersions: true,
      tagGroups: Infinity,
      teams: true,
      versionObjects: true,
      versionRevert: true,
      versions: Infinity
    } as IOrganizationLimits
  } else {
    return {
      actionLogDays: 30,
      diagramDrafts: 1,
      diagramGroups: 0,
      domains: 1,
      flowPaths: false,
      flows: 3,
      flowsPerDiagram: 1,
      landscapes: 2,
      links: 10,
      linksPerItem: 1,
      modelObjects: 100,
      seats: organization ? organization.seats : 5,
      shareLinkProtected: false,
      shareLinkVersions: false,
      tagGroups: 2,
      teams: false,
      versionObjects: false,
      versionRevert: false,
      versions: 3
    } as IOrganizationLimits
  }
}
