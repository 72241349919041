import { differenceInDays, differenceInHours, formatDistanceToNowStrict } from 'date-fns'

export const plural = (value: string | number | undefined, suffix: string) => {
  if (value === undefined) {
    return ''
  }
  if (typeof value === 'string') {
    value = parseFloat(value)
  }
  return `${Math.max(0, value)} ${suffix}${value === 1 ? '' : 's'}`
}

export const endsIn = (value: string | number | Date | undefined, prefix = true) => {
  if (!value) {
    return ''
  }
  const date = new Date(value)
  const days = differenceInDays(date, new Date())
  const hours = differenceInHours(date, new Date())
  if (date < new Date() || (hours === 0 && days === 0)) {
    return 'ended'
  } else if (days > 0) {
    const text = formatDistanceToNowStrict(date, {
      roundingMethod: 'round',
      unit: 'day'
    })
    return prefix ? `ends in ${text}` : text
  } else {
    const text = formatDistanceToNowStrict(date, {
      roundingMethod: 'round',
      unit: 'hour'
    })
    return prefix ? `ends in ${text}` : text
  }
}
