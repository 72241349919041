import * as analytics from '@icepanel/app-analytics'
import { OrganizationBillingCurrency, OrganizationBillingCycle, OrganizationPlan, OrganizationStatus } from '@icepanel/platform-api-client'

export const organizationId: analytics.Property<string[]> = {
  displayName: 'Organization ID',
  name: 'organization_id',
  required: true,
  type: 'array'
}
export const originOrganizationId: analytics.Property<string[] | undefined> = {
  displayName: 'Origin Organization ID',
  name: 'origin_organization_id',
  required: false,
  type: 'array'
}
const userId: analytics.Property<string[]> = {
  displayName: 'User ID',
  name: 'user_id',
  required: true,
  type: 'array'
}
const landscapeId: analytics.Property<string[]> = {
  displayName: 'Landscape ID',
  name: 'landscape_id',
  required: true,
  type: 'array'
}

const appUrl: analytics.Property<string> = {
  displayName: 'App URL',
  name: 'app_url',
  required: true,
  type: 'string'
}
const billingCurrency: analytics.Property<OrganizationBillingCurrency | undefined> = {
  displayName: 'Billing currency',
  examples: ['cad', 'eur', 'gbp', 'usd'],
  name: 'billing_currency',
  required: false,
  type: 'string'
}
const billingCycle: analytics.Property<OrganizationBillingCycle | undefined> = {
  displayName: 'Billing cycle',
  examples: ['annually', 'monthly'],
  name: 'billing_cycle',
  required: false,
  type: 'string'
}
const billingPaymentMethod: analytics.Property<boolean> = {
  displayName: 'Billing payment method',
  name: 'billing_payment_method',
  required: true,
  type: 'boolean'
}
const cancelAt: analytics.Property<Date | undefined> = {
  displayName: 'Cancel at',
  name: 'cancel_at',
  required: false,
  type: 'date'
}
const canceledAt: analytics.Property<Date | undefined> = {
  displayName: 'Canceled at',
  name: 'canceled_at',
  required: false,
  type: 'date'
}
const createdAt: analytics.Property<Date> = {
  displayName: 'Created at',
  name: '$created',
  required: true,
  type: 'date'
}
const autoInviteDomains: analytics.Property<string[]> = {
  displayName: 'Auto invite domains',
  name: 'auto_invite_domains',
  required: true,
  type: 'array'
}
const email: analytics.Property<string> = {
  displayName: 'Email',
  name: '$email',
  required: true,
  type: 'string'
}
const name: analytics.Property<string> = {
  displayName: 'Name',
  name: '$name',
  required: true,
  type: 'string'
}
const plan: analytics.Property<OrganizationPlan> = {
  displayName: 'Plan',
  examples: ['free', 'indie', 'pilot', 'growth', 'isolation'],
  name: 'plan',
  required: true,
  type: 'string'
}
const planEndsAt: analytics.Property<Date | undefined> = {
  displayName: 'Plan ends at',
  name: 'plan_ends_at',
  required: false,
  type: 'date'
}
const landscapeCount: analytics.Property<number> = {
  displayName: 'Landscape count',
  name: 'landscape_count',
  required: true,
  type: 'number'
}
const shareLinksEnabled: analytics.Property<boolean> = {
  displayName: 'Share links enabled',
  name: 'share_links_enabled',
  required: true,
  type: 'boolean'
}
const shareLinkAuthDomains: analytics.Property<string[] | null> = {
  displayName: 'Share link auth domains',
  name: 'share_link_auth_domains',
  required: true,
  type: 'array'
}
const seats: analytics.Property<number> = {
  displayName: 'Seats',
  name: 'seats',
  required: true,
  type: 'number'
}
const status: analytics.Property<OrganizationStatus> = {
  displayName: 'Status',
  examples: ['active', 'canceled', 'incomplete', 'incomplete_expired', 'past_due', 'trialing', 'unpaid'],
  name: 'status',
  required: true,
  type: 'string'
}
const trialEndsAt: analytics.Property<Date | undefined> = {
  displayName: 'Trial ends at',
  name: 'trial_ends_at',
  required: false,
  type: 'date'
}
const userCount: analytics.Property<number> = {
  displayName: 'User count',
  name: 'user_count',
  required: true,
  type: 'number'
}
const userReadCount: analytics.Property<number> = {
  displayName: 'User read count',
  name: 'user_read_count',
  required: true,
  type: 'number'
}
const userWriteCount: analytics.Property<number> = {
  displayName: 'User write count',
  name: 'user_write_count',
  required: true,
  type: 'number'
}
const userAdminCount: analytics.Property<number> = {
  displayName: 'User admin count',
  name: 'user_admin_count',
  required: true,
  type: 'number'
}

const goalsCompletedHidden: analytics.Property<string[]> = {
  displayName: 'Goals completed',
  examples: [
    [
      'add-model-object-description',
      'contact-icepanel',
      'create-flow',
      'create-object-in-model-view',
      'create-version',
      'find-golden-brian',
      'invite-organization-member',
      'share-link-visitor',
      'submit-feedback',
      'tag-model-object'
    ]
  ],
  hidden: true,
  name: 'goals_completed',
  required: true,
  type: 'array'
}
const goalsCompletedCountHidden: analytics.Property<number> = {
  displayName: 'Goals completed count',
  hidden: true,
  name: 'goals_completed_count',
  required: true,
  type: 'number'
}

export default new analytics.Group('organization_id', {
  appUrl,
  autoInviteDomains,
  billingCurrency,
  billingCycle,
  billingPaymentMethod,
  cancelAt,
  canceledAt,
  createdAt,
  email,
  goalsCompletedCountHidden,
  goalsCompletedHidden,
  landscapeCount,
  landscapeId,
  name,
  plan,
  planEndsAt,
  seats,
  shareLinkAuthDomains,
  shareLinksEnabled,
  status,
  trialEndsAt,
  userAdminCount,
  userCount,
  userId,
  userReadCount,
  userWriteCount
})
