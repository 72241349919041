import * as analytics from '@icepanel/app-analytics'

import { landscapeId } from '@/modules/landscape/helpers/analytics-group'
import { userEmail, userExists, userPermission } from '@/modules/user/helpers/analytics-properties'

import { organizationId } from './analytics-group'
import {
  organizationApiKeyName,
  organizationApiKeyPermission,
  organizationAzureDevopsAccountName,
  organizationBillingCurrency,
  organizationBillingCycle,
  organizationBillingEmail,
  organizationBitbucketServerUrl,
  organizationBitbucketServerUsername,
  organizationBitbucketWorkspaceName,
  organizationGitlabGroupName,
  organizationGoalNameHidden,
  organizationGoalProgressCountHidden,
  organizationGoalProgressDataHidden,
  organizationGoalStepCountHidden,
  organizationName,
  organizationPlan,
  organizationSeats,
  organizationShareLinkAuthDomains,
  organizationShareLinksEnabled,
  organizationStatus,
  organizationTrial,
  organizationUpgradePlan,
  organizationUpgradeTrial,
  organizationUpgradeTrigger
} from './analytics-properties'

/**
 * Screens
 */

export const organizationOverviewScreen = new analytics.Event({
  displayName: 'Organization overview screen',
  name: 'organization_overview_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationBillingScreen = new analytics.Event({
  displayName: 'Organization billing screen',
  name: 'organization_billing_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationIntegrationsScreen = new analytics.Event({
  displayName: 'Organization integrations',
  name: 'organization_integrations',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationApiKeysScreen = new analytics.Event({
  displayName: 'Organization api keys screen',
  name: 'organization_api_keys_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationSharingScreen = new analytics.Event({
  displayName: 'Organization sharing screen',
  name: 'organization_sharing_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationDiagrammingScreen = new analytics.Event({
  displayName: 'Organization diagramming screen',
  name: 'organization_diagramming_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationMembersScreen = new analytics.Event({
  displayName: 'Organization members screen',
  name: 'organization_members_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationTeamsScreen = new analytics.Event({
  displayName: 'Organization teams screen',
  name: 'organization_teams_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationGithubInstallScreen = new analytics.Event({
  displayName: 'Organization GitHub install screen',
  name: 'organization_github_install_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationAzureDevopsAuthenticationScreen = new analytics.Event({
  displayName: 'Organization Azure DevOps authentication screen',
  name: 'organization_azure_devops_authentication_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationBitbucketAuthenticationScreen = new analytics.Event({
  displayName: 'Organization Bitbucket authentication screen',
  name: 'organization_bitbucket_authentication_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationGitlabAuthenticationScreen = new analytics.Event({
  displayName: 'Organization GitLab authentication screen',
  name: 'organization_gitlab_authentication_screen',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
export const organizationUserInviteScreen = new analytics.Event({
  displayName: 'Organization user invite screen',
  name: 'organization_user_invite_screen',
  tags: [analytics.Tags.Screen]
}, {
  landscapeId,
  organizationId
})

/**
 * Dialogs
 */

export const organizationCreateDialog = new analytics.Event({
  displayName: 'Organization create dialog',
  name: 'organization_create_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationUpgradePricingDialog = new analytics.Event({
  displayName: 'Organization upgrade pricing dialog',
  name: 'organization_upgrade_pricing_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId,
  organizationUpgradeTrigger
})
export const organizationUpgradeFeaturesDialog = new analytics.Event({
  displayName: 'Organization upgrade features dialog',
  name: 'organization_upgrade_features_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId,
  organizationUpgradeTrigger
})
export const organizationUserInviteDialog = new analytics.Event({
  displayName: 'Organization user invite dialog',
  name: 'organization_user_invite_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId
})
export const organizationUserRemoveDialog = new analytics.Event({
  displayName: 'Organization user remove dialog',
  name: 'organization_user_remove_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationApiKeyCreateDialog = new analytics.Event({
  displayName: 'Organization api key create dialog',
  name: 'organization_api_key_create_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationApiKeyUpdateDialog = new analytics.Event({
  displayName: 'Organization api key update dialog',
  name: 'organization_api_key_update_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationApiKeyRemoveDialog = new analytics.Event({
  displayName: 'Organization api key remove dialog',
  name: 'organization_api_key_remove_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationBitbucketServerTokenCreateDialog = new analytics.Event({
  displayName: 'Organization Bitbucket server token create dialog',
  name: 'organization_bitbucket_server_token_create_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationBitbucketServerTokenUpdateDialog = new analytics.Event({
  displayName: 'Organization Bitbucket server token update dialog',
  name: 'organization_bitbucket_server_token_update_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationDeleteDialog = new analytics.Event({
  displayName: 'Organization delete dialog',
  name: 'organization_delete_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})

/**
 * Menus
 */

export const organizationListMenu = new analytics.Event({
  displayName: 'Organization list menu',
  name: 'organization_list_menu',
  tags: [analytics.Tags.Menu]
}, {
  organizationId
})
export const organizationGoalsMenu = new analytics.Event({
  displayName: 'Organization goals menu',
  name: 'organization_goals_menu',
  tags: [analytics.Tags.Menu]
}, {
  landscapeId,
  organizationId
})
export const organizationUpgradeMenu = new analytics.Event({
  displayName: 'Organization upgrade menu',
  name: 'organization_upgrade_menu',
  tags: [analytics.Tags.Menu]
}, {
  organizationId: {
    ...organizationId,
    required: false
  },
  organizationUpgradeTrigger
})

/**
 * Links
 */

export const organizationBillingLink = new analytics.Event({
  displayName: 'Organization billing link',
  name: 'organization_billing_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationAzureDevopsAuthorizationSetupLink = new analytics.Event({
  displayName: 'Organization Azure DevOps authorization setup link',
  name: 'organization_azure_devops_authorization_setup_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationBitbucketAuthorizationSetupLink = new analytics.Event({
  displayName: 'Organization Bitbucket authorization setup link',
  name: 'organization_bitbucket_authorization_setup_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationAzureDevopsAuthorizationEditLink = new analytics.Event({
  displayName: 'Organization Azure DevOps authorization edit link',
  name: 'organization_azure_devops_authorization_edit_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationBitbucketAuthorizationEditLink = new analytics.Event({
  displayName: 'Organization Bitbucket authorization edit link',
  name: 'organization_bitbucket_authorization_edit_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationGithubInstallSetupLink = new analytics.Event({
  displayName: 'Organization GitHub install setup link',
  name: 'organization_github_install_setup_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationGithubInstallEditLink = new analytics.Event({
  displayName: 'Organization GitHub install edit link',
  name: 'organization_github_install_edit_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationGitlabAuthorizationSetupLink = new analytics.Event({
  displayName: 'Organization GitLab authorization setup link',
  name: 'organization_gitlab_authorization_setup_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})
export const organizationGitlabAuthorizationEditLink = new analytics.Event({
  displayName: 'Organization GitLab authorization edit link',
  name: 'organization_gitlab_authorization_edit_link',
  tags: [analytics.Tags.Link]
}, {
  organizationId
})

/**
 * Events
 */

export const organizationCreate = new analytics.Event({
  displayName: 'Organization create',
  name: 'organization_create'
}, {
  organizationBillingCurrency,
  organizationBillingCycle,
  organizationBillingEmail,
  organizationId,
  organizationName,
  organizationPlan,
  organizationSeats,
  organizationStatus
})
export const organizationUpdate = new analytics.Event({
  displayName: 'Organization update',
  name: 'organization_update'
}, {
  organizationBillingEmail: {
    ...organizationBillingEmail,
    required: false
  },
  organizationId,
  organizationName: {
    ...organizationName,
    required: false
  },
  organizationShareLinkAuthDomains: {
    ...organizationShareLinkAuthDomains,
    required: false
  },
  organizationShareLinksEnabled: {
    ...organizationShareLinksEnabled,
    required: false
  }
})
export const organizationBillingSubscriptionCreate = new analytics.Event({
  displayName: 'Organization billing subscription create',
  name: 'organization_billing_subscription_create'
}, {
  organizationBillingCurrency,
  organizationBillingCycle,
  organizationId,
  organizationPlan,
  organizationSeats,
  organizationTrial
})
export const organizationBillingSubscriptionUpdate = new analytics.Event({
  displayName: 'Organization billing subscription update',
  name: 'organization_billing_subscription_update'
}, {
  organizationId,
  organizationPlan: {
    ...organizationPlan,
    required: false
  },
  organizationSeats: {
    ...organizationSeats,
    required: false
  },
  organizationTrial: {
    ...organizationTrial,
    required: false
  }
})
export const organizationBillingSubscriptionLinkCreate = new analytics.Event({
  displayName: 'Organization billing subscription link create',
  name: 'organization_billing_subscription_link_create'
}, {
  organizationBillingCurrency,
  organizationBillingCycle,
  organizationId,
  organizationPlan,
  organizationSeats,
  organizationTrial
})
export const organizationUserInviteCreate = new analytics.Event({
  displayName: 'Organization user invite create',
  name: 'organization_user_invite_create'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationId,
  userEmail,
  userExists,
  userPermission
})
export const organizationUpgradeSuccess = new analytics.Event({
  displayName: 'Organization upgrade success',
  name: 'organization_upgrade_success'
}, {
  organizationId,
  organizationUpgradePlan,
  organizationUpgradeTrial,
  organizationUpgradeTrigger
})
export const organizationUpgradeCancel = new analytics.Event({
  displayName: 'Organization upgrade cancel',
  name: 'organization_upgrade_cancel'
}, {
  organizationId,
  organizationUpgradePlan,
  organizationUpgradeTrial,
  organizationUpgradeTrigger
})

export const organizationApiKeyCreate = new analytics.Event({
  displayName: 'Organization api key create',
  name: 'organization_api_key_create'
}, {
  organizationApiKeyName,
  organizationApiKeyPermission,
  organizationId
})
export const organizationApiKeyUpdate = new analytics.Event({
  displayName: 'Organization api key update',
  name: 'organization_api_key_update'
}, {
  organizationApiKeyName: {
    ...organizationApiKeyName,
    required: false
  },
  organizationApiKeyPermission: {
    ...organizationApiKeyPermission,
    required: false
  },
  organizationId
})
export const organizationApiKeyDelete = new analytics.Event({
  displayName: 'Organization api key delete',
  name: 'organization_api_key_delete'
}, {
  organizationApiKeyName,
  organizationApiKeyPermission,
  organizationId
})
export const organizationAzureDevopsAuthorizationCreate = new analytics.Event({
  displayName: 'Organization Azure DevOps authorization create',
  name: 'organization_azure_devops_authorization_create'
}, {
  organizationId
})
export const organizationAzureDevopsAuthorizationUpdate = new analytics.Event({
  displayName: 'Organization Azure DevOps authorization update',
  name: 'organization_azure_devops_authorization_update'
}, {
  organizationAzureDevopsAccountName: {
    ...organizationAzureDevopsAccountName,
    required: false
  },
  organizationId
})
export const organizationAzureDevopsAuthorizationDelete = new analytics.Event({
  displayName: 'Organization Azure DevOps authorization delete',
  name: 'organization_azure_devops_authorization_delete'
}, {
  organizationId
})
export const organizationBitbucketAuthorizationCreate = new analytics.Event({
  displayName: 'Organization Bitbucket authorization create',
  name: 'organization_bitbucket_authorization_create'
}, {
  organizationId
})
export const organizationBitbucketAuthorizationUpdate = new analytics.Event({
  displayName: 'Organization Bitbucket authorization update',
  name: 'organization_bitbucket_authorization_update'
}, {
  organizationBitbucketWorkspaceName: {
    ...organizationBitbucketWorkspaceName,
    required: false
  },
  organizationId
})
export const organizationBitbucketAuthorizationDelete = new analytics.Event({
  displayName: 'Organization Bitbucket authorization delete',
  name: 'organization_bitbucket_authorization_delete'
}, {
  organizationId
})
export const organizationBitbucketServerTokenCreate = new analytics.Event({
  displayName: 'Organization Bitbucket server token create',
  name: 'organization_bitbucket_server_token_create'
}, {
  organizationBitbucketServerUrl,
  organizationBitbucketServerUsername,
  organizationId
})
export const organizationBitbucketServerTokenUpdate = new analytics.Event({
  displayName: 'Organization Bitbucket server token update',
  name: 'organization_bitbucket_server_token_update'
}, {
  organizationBitbucketServerUrl: {
    ...organizationBitbucketServerUrl,
    required: false
  },
  organizationBitbucketServerUsername: {
    ...organizationBitbucketServerUsername,
    required: false
  },
  organizationId
})
export const organizationBitbucketServerTokenDelete = new analytics.Event({
  displayName: 'Organization Bitbucket server token delete',
  name: 'organization_bitbucket_server_token_delete'
}, {
  organizationBitbucketServerUrl,
  organizationBitbucketServerUsername,
  organizationId
})

export const organizationGithubInstallCreate = new analytics.Event({
  displayName: 'Organization GitHub install create',
  name: 'organization_github_install_create'
}, {
  organizationId
})
export const organizationGithubInstallDelete = new analytics.Event({
  displayName: 'Organization GitHub install delete',
  name: 'organization_github_install_delete'
}, {
  organizationId
})

export const organizationGitlabAuthorizationCreate = new analytics.Event({
  displayName: 'Organization GitLab authorization create',
  name: 'organization_gitlab_authorization_create'
}, {
  organizationId
})
export const organizationGitlabAuthorizationUpdate = new analytics.Event({
  displayName: 'Organization GitLab authorization update',
  name: 'organization_gitlab_authorization_update'
}, {
  organizationGitlabGroupName: {
    ...organizationGitlabGroupName,
    required: false
  },
  organizationId
})
export const organizationGitlabAuthorizationDelete = new analytics.Event({
  displayName: 'Organization GitLab authorization delete',
  name: 'organization_gitlab_authorization_delete'
}, {
  organizationId
})
export const organizationDelete = new analytics.Event({
  displayName: 'Organization delete',
  name: 'organization_delete'
}, {
  organizationId
})

/**
 * Hidden
 */

export const organizationGoalProgressHidden = new analytics.Event({
  displayName: 'Organization goal progress',
  hidden: true,
  name: 'organization_goal_progress'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationGoalNameHidden,
  organizationGoalProgressCountHidden,
  organizationGoalProgressDataHidden,
  organizationGoalStepCountHidden,
  organizationId
})
export const organizationGoalCompleteHidden = new analytics.Event({
  displayName: 'Organization goal complete',
  hidden: true,
  name: 'organization_goal_complete'
}, {
  landscapeId: {
    ...landscapeId,
    required: false
  },
  organizationGoalNameHidden,
  organizationGoalProgressCountHidden,
  organizationGoalProgressDataHidden,
  organizationGoalStepCountHidden,
  organizationId
})
export const organizationGoalPrizeLinkHidden = new analytics.Event({
  displayName: 'Organization goal prize link',
  hidden: true,
  name: 'organization_goal_prize_link'
}, {
  landscapeId,
  organizationId
})
export const organizationUpgradeDialogHidden = new analytics.Event({
  displayName: 'Organization upgrade dialog',
  hidden: true,
  name: 'organization_upgrade_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId,
  organizationUpgradeTrigger
})
export const organizationBitbucketLinkDialogHidden = new analytics.Event({
  displayName: 'Organization Bitbucket link dialog',
  hidden: true,
  name: 'organization_bitbucket_link_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationBitbucketCloudInstallDialogHidden = new analytics.Event({
  displayName: 'Organization Bitbucket cloud install dialog',
  hidden: true,
  name: 'organization_bitbucket_cloud_install_dialog',
  tags: [analytics.Tags.Dialog]
}, {
  organizationId
})
export const organizationCodeRepoIntegrationsScreen = new analytics.Event({
  displayName: 'Organization code repo integrations',
  hidden: true,
  name: 'organization_code_repo_integrations',
  tags: [analytics.Tags.Screen]
}, {
  organizationId
})
